<footer class="bg-primary footer">
    <div fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutAlign="center center" class="border-bottom-mute subscribe-block theme-container">
        <div fxFlex="100" fxFlex.gt-sm="40" fxLayout="column" fxLayoutAlign="center center"> 
            <h1 class="fw-500">Subscribete en nuestro canal de comunicaciones</h1>
            <h3 class="fw-300 secondary-color">Te notificaremos de nuestras novedades y nuevos productos</h3>
        </div>
        <form method="get" class="subscribe-search-form" fxLayout="row" fxFlex="100" fxFlex.gt-sm="42.4" ngClass.sm="mt-2" ngClass.xs="mt-2">
            <input type="text" placeholder="Cuenta de correo..." fxFlex>
            <button mat-raised-button color="accent" (click)="subscribe()" type="button" class="mat-elevation-z0 text-muted">Subscribete</button>
        </form>
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="space-between" class="py-3 border-bottom-mute theme-container">        
        <div fxFlex="100" fxFlex.gt-sm="20" fxFlex.sm="40"> 
            <h3 class="col-title">LINKS DE AYUDA</h3>
            <p class="mt-2"><a href="javascript:void(0);" class="link secondary-color">Mi cuenta</a></p>
            <p class="mt-1"><a routerLink="/contact" class="link secondary-color">Contacto</a></p>
            <p class="mt-1"><a routerLink="/compare" class="link secondary-color">Comparar</a></p>
            <p class="mt-1"><a routerLink="/checkout" class="link secondary-color">Logout</a></p>
            <p class="mt-1"><a href="javascript:void(0);" class="link secondary-color">FAQ</a></p>
        </div>
        <div fxFlex="100" fxFlex.gt-sm="30" fxFlex.sm="60" ngClass.xs="mt-2" class="contact-information">
          <h3 class="col-title">INFORMACION DE CONTACTO</h3>
          <p fxLayout="row" fxLayoutAlign="start center" class="mt-2 secondary-color">
            <mat-icon>location_on</mat-icon>
            <span>calle 17a # 69f-36, Bogotá, Cundinamarca</span>
          </p>
          <p fxLayout="row" fxLayoutAlign="start center" class="mt-1 secondary-color">
            <mat-icon>call</mat-icon>
            <span>601-4897320</span>
          </p>
          <p fxLayout="row" fxLayoutAlign="start center" class="mt-1 secondary-color">
            <mat-icon>mail_outline</mat-icon>
            <span>Gilberto.ortiz@suprisa.com.co</span>
          </p>
          <p fxLayout="row" fxLayoutAlign="start center" class="mt-1 secondary-color">
            <mat-icon>schedule</mat-icon>
            <span>Lunes - Sabado / 7:00AM - 7:00PM</span>
          </p>
          <p fxLayout="row" fxLayoutAlign="start center" class="mt-1 secondary-color">
            <mat-icon>directions</mat-icon>
            <a href="javascript:void(0);" class="link secondary-color">Get directions</a>
          </p>
          <!-- <p fxLayout="row" fxLayoutAlign="start center" class="mt-1 secondary-color">
       <mat-icon>directions_bus</mat-icon>
       <span>Routes to us</span>
   </p>-->
        </div>
        <div fxFlex="100" fxFlex.gt-sm="50" ngClass.sm="mt-2" ngClass.xs="mt-2"> 
            <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom" [mapTypeControl]="true">
                <agm-marker [latitude]="lat" [longitude]="lng" [markerDraggable]="true"></agm-marker>
            </agm-map>
        </div>
    </div>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" class="copyright secondary-color theme-container">
        <p ngClass.xs="mt-1">Copyright © 2022 Todos los derechos reservados</p>
        <p>Diseñado y Desarrollado por: <a mat-button href="www.softmarket.com.co" target="_blank">Softmarket</a></p>
    </div>
</footer>
