<div class="top-menu">    

   
    <span fxShow="false" fxShow.gt-sm>
        <a mat-button routerLink="/compare">{{ 'COMPARE' | translate }} <span *ngIf="settings.rtl">&rlm;</span>({{appService.Data.compareList.length}})</a>
        <a mat-button routerLink="/wishlist">Lista<span *ngIf="settings.rtl">&rlm;</span>({{appService.Data.wishList.length}})</a>
    </span>  

    <a mat-button [matMenuTriggerFor]="accountMenu" #accountMenuTrigger="matMenuTrigger">
        <mat-icon class="mat-icon-sm account-icon">person</mat-icon> 
        <span fxShow="false" fxShow.gt-sm>{{ 'ACCOUNT' | translate }}</span> 
        <mat-icon class="mat-icon-sm caret">arrow_drop_down</mat-icon>
    </a>
    <mat-menu #accountMenu="matMenu" [overlapTrigger]="false" xPosition="before" class="app-dropdown account">
        <span (mouseleave)="accountMenuTrigger.closeMenu()">
            <div fxLayout="row" fxLayoutAlign="space-around center" class="user-info">                   
                <img src="assets/images/others/user.jpg" alt="user-image" width="50">
                <p>Usuario Suprisa <br> <small>Usr Srprisa<span *ngIf="settings.rtl">&rlm;</span>(152<mat-icon class="mat-icon-xs">star</mat-icon>)</small></p>
            </div>
            <div class="divider"></div>
            <a mat-menu-item routerLink="/account"> 
                <mat-icon class="mat-icon-sm">settings</mat-icon>
                <span>Configuración de Cuenta</span> 
            </a>
            <a mat-menu-item routerLink="/compare" fxHide="false" fxHide.gt-sm>
                <mat-icon class="mat-icon-sm">comparar</mat-icon>
                <span>Comparar <span *ngIf="settings.rtl">&rlm;</span>({{appService.Data.compareList.length}})</span> 
            </a>
            <a mat-menu-item routerLink="/wishlist" fxHide="false" fxHide.gt-sm>
                <mat-icon class="mat-icon-sm">favorito</mat-icon>
                <span>Wishlist <span *ngIf="settings.rtl">&rlm;</span>({{appService.Data.wishList.length}})</span>
            </a>
            <a mat-menu-item routerLink="/"> 
                <mat-icon class="mat-icon-sm">lock</mat-icon>
                <span>Lock screen</span> 
            </a>
            <a mat-menu-item routerLink="/"> 
                <mat-icon class="mat-icon-sm">help</mat-icon>
                <span>Ayuda</span> 
            </a>
            <div class="divider"></div>
            <a mat-menu-item routerLink="/sign-in"> 
                <mat-icon class="mat-icon-sm">power_settings_new</mat-icon>
                <span>{{ 'LOGOUT' | translate }}</span> 
            </a>
        </span>
    </mat-menu>    

</div>  
